import { useQuery } from "@tanstack/react-query";

import AnnouncementService from "app/api/announcements";

export default function useListAnnouncements(country?: string) {
  const { data, status, error, refetch, isFetching, isLoading, fetchStatus } =
    useQuery(["announcements"], () =>
      AnnouncementService.listAnnouncements(country)
    );

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
    isLoading,
    fetchStatus,
  };
}
