import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import parseError from "app/lib/parseError";
import { getProfiles } from "app/api/profile";
import {
  useActiveProfile,
  useSetActiveProfile,
} from "app/providers/ActiveProfileProvider";
import { PROFILES_QUERY } from "app/constants/commonQueries";
import { parseToken } from "app/lib/encryption";
import { useProfile } from "app/providers/ProfileProvider";

/**
 * Custom hook to get user profiles.
 *
 * @param enabled - A boolean to enable or disable the fetching of profiles.
 */

const useGetProfiles = (enabled = true) => {
  const activeProfile = useActiveProfile();

  const profile = useProfile();

  const setActiveProfile = useSetActiveProfile();

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    [PROFILES_QUERY],
    getProfiles,
    {
      enabled,
      // 5 minutes
      staleTime: 1000 * 60 * 5,
    }
  );

  useEffect(() => {
    if (activeProfile) {
      const token = parseToken();

      if ((token?.uid as string) === activeProfile.user_id) return;
    }

    if (!data) return;

    const profiles = data.data.data;

    const defaultSelectedProfile = profile?.business_default
      ? "business"
      : activeProfile?.profile_type || "personal";

    // Profile selected by default
    const defaultProfile = profiles.find(
      (p) => p.profile_type === defaultSelectedProfile
    );

    setActiveProfile(defaultProfile ?? profiles[0]);
  }, [activeProfile, data, setActiveProfile, profile]);

  return {
    profiles: data?.data.data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getProfiles: refetch,
    isFetching,
    isLoading,
  };
};

export default useGetProfiles;
