import Modal from "app/components/ui/Modal";
import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import { ReactComponent as PadLockClose } from "app/assets/icons/padlock-close.svg";
import { useNavigate } from "react-router-dom";
import useGetSecurity from "app/hooks/useGetSecurity";

export default function EnableTwoStepAuthModal() {
  const navigate = useNavigate();

  const { security } = useGetSecurity();
  return (
    <Modal
      open={security?.two_step === false}
      onClose={() => navigate("/profile/payment-methods/bank-accounts")}
      title="Enable 2FA"
      tw="max-w-[480px] max-h-full"
    >
      <div tw="p-[24px_20px_20px_40px]">
        <PadLockClose tw="mx-auto" />

        <div tw="mt-[36px] text-center">
          <SubHeader big>Enable 2FA</SubHeader>
          <Text medium subdued tw="mt-[8px]">
            Two-factor authentication is needed to add a new payment account.
            Please enable your 2FA to continue
          </Text>
        </div>

        <div tw="flex flex-col gap-[8px] mt-[32px]">
          <Button
            primary
            fullWidth
            onClick={() => navigate("/profile/security/two-step-auth")}
          >
            Enable 2FA
          </Button>
          <Button outline fullWidth>
            Learn More
          </Button>
        </div>
      </div>
    </Modal>
  );
}
