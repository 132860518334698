import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { queryClient } from "app/providers/QueryProvider";

import storage from "app/lib/storage";
import {
  ACTIVE_PROFILE_STORAGE_KEY,
  API_URL,
  MEDIA_SERVICE_BASE_URL,
  PAYMENT_LINKS_PATH,
  TOKEN_STORAGE_KEY,
  UNAUTHORIZED_ERROR_CODE,
} from "app/constants/variables";
import { TokenContextType } from "app/providers/TokenProvider";
import { cleanupStorage, gotoSignout } from "app/lib/logout";
import { LoginResponse } from "./auth/types";
import { REFERESH_ACCESS_TOKEN_QUERY } from "app/constants/commonQueries";

const request = axios.create({
  baseURL: API_URL,
});

request.interceptors.request.use((config) => {
  const { access_token } = storage.get(TOKEN_STORAGE_KEY) ?? {};
  const { id } = storage.get(ACTIVE_PROFILE_STORAGE_KEY) ?? {};

  const newConfig: AxiosRequestConfig = {
    ...config,
  };

  if (!newConfig.headers) return newConfig;

  const requiresNoToken = newConfig.headers["noToken"] as boolean;

  delete newConfig.headers["noToken"];

  if (!access_token || requiresNoToken) return newConfig;

  newConfig.headers = {
    ...newConfig.headers,
    Authorization: `Bearer ${access_token}`,
  };

  // console.log({ config: config.baseURL });

  if (
    !id ||
    config.baseURL === MEDIA_SERVICE_BASE_URL // added to avoid CORS error when uploading file
  )
    return newConfig;

  newConfig.headers = {
    ...newConfig.headers,
    "X-BU-PROFILE-ID": id,
  };

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    const initialConfig = error.config;

    const { access_token, refresh_token } =
      (storage.get(TOKEN_STORAGE_KEY) as TokenContextType) ?? {};

    const currentPathname = window.location.pathname;
    const pathnames = currentPathname.split("/");
    const isPayPathname = pathnames[1] === PAYMENT_LINKS_PATH;

    // if user's token has expired or has been blacklisted
    if (error.response?.status === UNAUTHORIZED_ERROR_CODE && access_token) {
      if (!initialConfig) return gotoSignout();

      if (refresh_token) {
        // await queryClient.cancelQueries();

        try {
          const res = await queryClient.fetchQuery<
            AxiosResponse<LoginResponse>
          >([REFERESH_ACCESS_TOKEN_QUERY], {
            staleTime: 1000 * 60 * 2,
            retry: false,
          });

          initialConfig.headers = {
            ...initialConfig.headers,
            Authorization: `Bearer ${res.data.access_token}`,
          };

          return axios.request(initialConfig);
        } catch (error) {
          if (isPayPathname) {
            cleanupStorage();
            return Promise.reject(error);
          }
          return gotoSignout();
        }
      } else {
        if (isPayPathname) {
          cleanupStorage();
          return Promise.reject(error);
        }
        return gotoSignout();
      }
    }
    return Promise.reject(error);
  }
);

export default request;
