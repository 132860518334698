import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import useKYBSteps from "app/hooks/useKYBSteps";
import {
  BusinessVerificationSection,
  BusinessVerificationSectionData,
  BusinessVerificationSectionKeys,
  KYBStep,
} from "app/models/businessVerification";
import useGetAllBusinessVerificationSections from "app/hooks/useGetAllBusinessVerificationSections";
import LoaderContainer from "app/components/ui/LoaderContainer";
import { transformSectionsToSteps } from "app/utils/businessVerification";
import isEmpty from "app/lib/isEmpty";

interface KYBStepNavigationContextProps {
  steps: KYBStep[];
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  currentStep: KYBStep;
  isFirstKYBStep: boolean;
  isLastKYBStep: boolean;
  currentStepData: BusinessVerificationSectionData;
  getSection: (
    section_key: BusinessVerificationSectionKeys
  ) => BusinessVerificationSection | null;
  sections: BusinessVerificationSection[];
}

const KYBStepNavigationContext =
  createContext<KYBStepNavigationContextProps | null>(null);

export const useKYBStepNavigationContext = () => {
  const context = useContext(KYBStepNavigationContext);
  if (!context) {
    throw new Error(
      "useKYBStepNavigationContext must be used within KYBStepNavigationProvider"
    );
  }
  return context;
};

export const KYBStepNavigationProvider = ({
  children,
  noRedirect,
}: PropsWithChildren<{ noRedirect?: boolean }>) => {
  const [steps, setSteps] = useState<KYBStep[]>([]);

  const { sections, isLoading, error, getSections } =
    useGetAllBusinessVerificationSections();

  const {
    goToNextStep,
    goToPreviousStep,
    currentStep,
    isFirstKYBStep,
    isLastKYBStep,
  } = useKYBSteps(steps);

  useEffect(() => {
    if (sections) {
      const transformedSteps = transformSectionsToSteps(sections);
      setSteps(transformedSteps);
    }
  }, [sections]);

  const onRetry = () => getSections();

  const currentStepData: BusinessVerificationSectionData = useMemo(() => {
    const currentSection = sections?.find(
      (section) => section?.section_key === currentStep?.section_key
    );

    return currentSection?.data as BusinessVerificationSectionData;
  }, [sections, currentStep]);

  const getSection = (section_key: string) => {
    if (isEmpty(section_key)) return null;

    return (
      sections?.find((section) => section.section_key === section_key) || null
    );
  };

  return (
    <LoaderContainer
      loading={isLoading}
      error={!!error}
      errorMessage={error}
      onRetry={onRetry}
      screen
    >
      <KYBStepNavigationContext.Provider
        value={{
          steps,
          goToNextStep,
          goToPreviousStep,
          currentStep,
          isFirstKYBStep,
          isLastKYBStep,
          currentStepData,
          getSection,
          sections: sections || [],
        }}
      >
        {children}
      </KYBStepNavigationContext.Provider>
    </LoaderContainer>
  );
};
